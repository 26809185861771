<template>
  <div class="">
    <trac-loading v-if="isLoading" />

    <main class="md:ml-12 mr-4 md:mr-0" v-if="products">
      <trac-back-button class="capitalize">{{
        editCategory ? categoryName : "Categories"
      }}</trac-back-button>
      <div class="flex-col mt-12 mb-8">
        <h3 class="font-bold mt-4 text-sm">
          ({{ selectedProducts.length }} Product{{
            selectedProducts.length > 1 ? "s" : ""
          }}
          Selected)
        </h3>
      </div>
      <div class="bg-white py-6 rounded-lg h-screen overflow-scroll big-shadow">
        <div class="px-4 md:flex justify-between">
          <div class="mt-2 md:w-6/12 w-full">
            <trac-input
              class="md:mr-5"
              placeholder="Enter category name"
              v-model.trim="categoryName"
            ></trac-input>
          </div>
          <div class="mt-2">
            <trac-button
            data-test="save-category"
              @button-clicked="editCategory ? updateCategoryAndReAssign() : addCategory()"
              :disabled="categoryName.length === 0"
              class="uppercase w-full"
              >{{
                editCategory ? "Update Cateogry" : "Save Category"
              }}</trac-button
            >
          </div>
        </div>
        <!-- <div
          class="mb-5 mx-8 justify-between overflow-auto grid md:flex flex-row items-center"
        >
          <div class="flex-column mt-2 md:w-6/12 w-full">
            <trac-input
              class="mr-5"
              placeholder="Enter category name"
              v-model.trim="categoryName"
            ></trac-input>
          </div>
          <div class="flex-column mt-2">
            <trac-button
              @button-clicked="editCategory ? updateCategory() : addCategory()"
              :disabled="categoryName.length === 0"
              class="uppercase"
              >{{
                editCategory ? "Update Cateogry" : "Save Category"
              }}</trac-button
            >
          </div>
        </div> -->
        <div
          class="
            mt-10
            mb-5
            mx-8
            grid
            md:flex
            flex-row
            items-center
            justify-between
          "
        >
          <span class="font-semibold text-gray-700 text-sm"
            >Select from the list below, items you would like to add to this
            category.</span
          >
          <span class="md:w-4/12 mt-2 md:mt-0">
            <div
              class="
                flex flex-row
                bg-white
                items-center
                rounded
                big-shadow
                justify-between
                px-2
              "
            >
              <span class="text-xs font-light text-primaryGray w-full">
                <input
                  class="w-full py-2 px-4 outline-none"
                  type="text"
                  name=""
                  id=""
                  v-model.trim="searchedProduct"
                  placeholder="Search for an item to add"
                />
              </span>
              <img src="../../assets/svg/Search_icon.svg" />
            </div>
          </span>
        </div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mr-8 ml-8 mb-8">
          <div
            v-for="(product, i) in sortedProducts"
            :key="i"
            class="border border-white hover:border-gray-200"
            @click="toggleSelect(product)"
            :class="
              selectedProducts.includes(product._id)
                ? 'border-primaryBlue hover:border-primaryBlue'
                : ''
            "
          >
            <div
              class="flex flex-col w-full h-full justify-between cursor-pointer"
            >
              <trac-image-placeholder
                :name="product.title"
                :imgURL="product.image"
                class="p-6"
                size="h-32"
              ></trac-image-placeholder>
              <div
                class="
                  flex
                  w-4/5
                  pt-3
                  mx-auto
                  mb-3
                  border-t
                  text-xs
                  justify-center
                  items-center
                  capitalize
                "
                :class="
                  selectedProducts.includes(product._id)
                    ? 'border-primaryBlue'
                    : ''
                "
              >
                <span class="text-center text-xs">{{ product.title }}</span>
              </div>
            </div>
          </div>
          <!-- <div
            @click="editCategory ? gotoProductCreatePage() : addCategory(true)"
            class="h-48 w-full rounded flex mx-auto my-auto bg-accentLight cursor-pointer"
          >
            <div class="flex-column mx-auto text-center my-auto">
              <img src="../../assets/svg/add_icon.svg" />
              <span class="font-medium text-sm"
                >Add <br />
                Product</span
              >
            </div>
          </div> -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";

import { eventBus } from "./../../main";

export default {
  name: "NewCategories",
  data() {
    return {
      isLoading: false,
      product: {},
      categoryName: "",
      searchedProduct: "",
      selectedProducts: [],
      products: null,
      createCategoryOnTheFly: null,
      editCategory: false,
    };
  },
  async created() {
    this.isLoading = true;

    // Stoped here
    this.createCategoryOnTheFly = GET_LOCAL_DB_DATA(
      "create-category-on-the-fly"
    );

    const categoryDetail = GET_LOCAL_DB_DATA("category-detail");
    // const fromProductModule = GET_LOCAL_DB_DATA("category-detail");
    await this.fetchAllProducts();
    // if (fromProductModule) {
    //   (this.categoryName = fromProductModule.categoryName),
    //     (this.selectedProducts = fromProductModule.selectedProducts);
    //   // DELETE_LOCAL_DB_DATA("category-detail");
    // }
    if (categoryDetail) {
      this.editCategory = true;
      this.categoryName = categoryDetail.category.name;
      this.setupSelectedProducts();
    }
    this.isLoading = false;
  },
  computed: {
    sortedProducts() {
      return this.filteredProducts.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
    filteredProducts() {
      return this.products.filter((product) =>
        product.title.toLowerCase().includes(this.searchedProduct.toLowerCase())
      );
    },
  },
  methods: {
    toggleSelect(product) {
      if (this.selectedProducts.includes(product._id)) {
        this.selectedProducts = this.selectedProducts.filter(
          (item) => item !== product._id
        );
      } else {
        this.selectedProducts.push(product._id);
      }
    },
    async fetchAllProducts() {
      this.products = await this.$GlobalOfflineManager.getAll('products') || []
    },
    gotoProductCreatePage() {
      const payload = {
        name: this.categoryName,
        // selectedProducts: this.selectedProducts,
      };
      SAVE_LOCAL_DB_DATA("category-detail", payload);
      this.$router.push({ name: "AddProduct" });
    },
    setupSelectedProducts() {
      // debugger
      this.products.forEach((product) => {
        if (
          product.category.toLowerCase() === this.categoryName.toLowerCase()
        ) {
          this.selectedProducts.push(product._id);
        }
      });
    },
    offlineSyncProductsAndCategories() {
      return this.requestSync('categories').then(() => {
        return this.requestSync('products')
      }).then(() => {
        console.log('Product Sync Requested after Category Sync')
      })
    },
    async updateCategoryAndReAssign(){
      this.isLoading = true;
      const allCategories = await this.$GlobalOfflineManager.getAll('categories') || []
      // get current category
      const currentCategory = this.categoryName
      // get uncategoried category (check if it exists or make null)
      const getUncategoried = allCategories.find(record => record.category.name === 'uncategoried') || null
      const uncategoried = getUncategoried ? getUncategoried.category.name : null
      // get prior selected products
      const priorSelected = this.products.filter(product => product.category === currentCategory).map(product => product._id) || []
      // get current selected products
      const selected = this.selectedProducts
      // filter out products that aren't selected any more
      const productsUncategoriezed = priorSelected.filter(product => !selected.includes(product)) || []
      // debugger

      // then update the current category with products
      const currentCategoryUpdate  = await this.addProductToCategoryEndpoint(currentCategory, selected)
      if(currentCategoryUpdate.status) {
        if(uncategoried){
          if(productsUncategoriezed.length > 0) {
            // update to endpoint
            console.log('products primed for uncategorization confirmed: ', productsUncategoriezed)
            await this.addProductToCategoryEndpoint(uncategoried, productsUncategoriezed).then((feedback) => {
              console.log('products added to uncategoried feedback: ', feedback)
            })
          }
        } else {
          // create the 'uncategorized' category first...
          const feedback = await this.createNewCategoryEndpoint('uncategoried').catch((err) => {
            console.error('this.createNewCategoryEndpoint error: ', err)
          })
          if (feedback.status) {
            // ... then add products to it
            const addUnCategorized = await this.addProductToCategoryEndpoint(uncategoried, productsUncategoriezed).catch((err) => {
              console.error('this.addProductToCategoryEndpoint error: ', err)
            })
            if(addUnCategorized.status) {
              console.log('addUnCategorized complete: ', uncategoried, productsUncategoriezed)
              // await this.offlineSyncProductsAndCategories()
            }
          }
        }
        await this.offlineSyncProductsAndCategories()
        this.$router.back();
      }

      // if uncategoried exists, update 
      // uncategorized products to it
      
      // update uncategoried category
      //     - if it doesn't, create category
      //         - then update with no longer selected products
      this.isLoading = false;
    },
    async createNewCategoryEndpoint(categoryName) {
      const payload = {
        name: categoryName,
      };
      await this.$store.dispatch("CREATE_CATEGORY", payload);
      const feedback = this.$store.getters["GET_RES_FEEDBACK"];

      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);
      return feedback
    },
    async addProductToCategoryEndpoint(categoryName = '', products = []) {
      await this.$store.dispatch("ADD_PRODUCT_TO_CATEGORY_V2", {
        products: products,
        category: categoryName,
      });

      const validateFeedback = this.$store.getters["GET_RES_FEEDBACK"];

      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);

      return validateFeedback
    },
    async updateCategory() {
      this.isLoading = true;
      await this.$store.dispatch("ADD_PRODUCT_TO_CATEGORY_V2", {
        products: this.selectedProducts,
        category: this.categoryName,
      });

      const validateFeedback = this.$store.getters["GET_RES_FEEDBACK"];
      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);

      if (validateFeedback.status) {
        this.$router.back();
      } else {
        // alert(validateFeedback.message);
        eventBus.$emit("trac-alert", { message: validateFeedback.message });
      }
      this.isLoading = false;
    },
    async addCategory() {
      this.isLoading = true;
      const payload = {
        name: this.categoryName,
      };

      // This vaidation seems to have an issue so commented for now.

      // await this.$store.dispatch("VALIDATE_CATEGORY_NAME", payload);
      // const validateFeedback = this.$store.getters["GET_RES_FEEDBACK"];

      // // Set feedback back to null for future operations
      // this.$store.commit("SETUP_FEEDBACK", null);

      // if (!validateFeedback.data) {
      //   alert(validateFeedback.message);
      //   return;
      // }

      if (true) {
        // if (validateFeedback.status) {
        await this.$store.dispatch("CREATE_CATEGORY", payload);
        const feedback = this.$store.getters["GET_RES_FEEDBACK"];

        // Set feedback back to null for future operations
        this.$store.commit("SETUP_FEEDBACK", null);

        if (feedback.status) {
          await this.$store.dispatch("ADD_PRODUCT_TO_CATEGORY_V2", {
            products: this.selectedProducts,
            category: this.categoryName,
          });

          const res = this.$store.getters["GET_RES_FEEDBACK"];

          // Set feedback back to null for future operations
          this.$store.commit("SETUP_FEEDBACK", null);

          if (res.status) {
            if (this.createCategoryOnTheFly) {
              // this.gotoProductCreatePage();
              const payload = {
                ...this.createCategoryOnTheFly.product,
                category: this.categoryName,
              };

              SAVE_LOCAL_DB_DATA("product-details", payload);
            }
            await this.offlineSyncProductsAndCategories().then(() => {
              this.$router.back();
            })
          } else {
            // alert(validateFeedback.message);
            eventBus.$emit("trac-alert", { message: res.message });
          }
          // Set feedback back to null for future operations
          this.$store.commit("SETUP_FEEDBACK", null);
        } else {
          // alert(validateFeedback.message);
          eventBus.$emit("trac-alert", { message: feedback.message });
          // Set feedback back to null for future operations
          this.$store.commit("SETUP_FEEDBACK", null);
        }
      } else {
        // alert(validateFeedback.message);
        eventBus.$emit("trac-alert", { message: validateFeedback.message });
        // Set feedback back to null for future operations
        this.$store.commit("SETUP_FEEDBACK", null);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.shadow-toggle:hover {
  border: #253b95;
}
</style>
